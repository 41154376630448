















































































import {Vue,Component, Prop} from 'vue-property-decorator'
import {TrozasQuery,PlanificacionRequest,PlanificacionItemRequest, PalletTablon, GuardarPlanificacion} from '@/entidades/KTB/Planificacion/PlanificacionListado';
import { State,Action } from 'vuex-class';
import { Planta } from '@/entidades/Maestro/Planta';
import { ObtenerTurnoPlanta, TurnoQuery } from '@/entidades/KTB/RegistroInformacion/Turno';
import Loading from '@/entidades/Sistema/Loading';
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue,decimal} from 'vuelidate/lib/validators'
import Alerta from '@/entidades/Sistema/Alerta';
@Component
export default class PlanificacionAdd extends Vue
{
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>;
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    @State('turno',{namespace: 'maestroKtb'}) listadoTurno!:Array<TurnoQuery>;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Prop({type:Array,required:false}) tablon!:Array<PalletTablon>
    @Prop({type:Array,required:false}) trozas!:Array<TrozasQuery>

    turnoId:number=0;
    menu:boolean=false;
    date:string="";
    volumenProduccion:number=0;
    //listadoTurno:Array<TurnoQuery> =[];

     @Validations()
     validations = {
         turnoId : {required,minValue:minValue(1)},
         date : {required},
         volumenProduccion : {decimal,required,minValue:minValue(1)},
     }


    async guardar()
    {
        try
        {
            await this.changeLoading(new Loading(true,"Registrando informacion...Espere por favor"));
            
            //validamos la hacienda

            //Armamos la cabecera de la planificacion
            let request:PlanificacionRequest = new PlanificacionRequest(this.plantas[0].plantaId,
                this.turnoId,this.date,this.volumenTrozas,this.volumenTablon,this.volumenProduccion,
                this.usuarioId);

            //armamos el detalle de la planificacio
            let detalle:Array<PlanificacionItemRequest> = [];
            if(this.trozas.length > 0)
            {
                this.trozas.forEach(c=> {
                    detalle.push(new PlanificacionItemRequest(c.recepcionId,0))
                    request.haciendaId = c.haciendId;
                })
            }
            if(this.tablon.length > 0)
            {
                this.tablon.forEach(c=> {
                    detalle.push(new PlanificacionItemRequest(c.recepcionId,c.recepcionDetalleId))
                    request.haciendaId = c.haciendaId;
                })
            }
            request.items = detalle;
            console.log(request);
            let response = await GuardarPlanificacion(request);
            if(response.isSuccess == true)
            {
                this.changeAlerta(new Alerta(response.isMessage,true,"success",3000));
                this.$emit('limpiar');
            }
            else
            {
                this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
            }

        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    get volumenTrozas()
    {
        let inicio:number=0;
        let suma =this.trozas.reduce(function(total,currentValue){
            return total + Number(currentValue.volumen)
        },inicio)
        return suma;
    }

    get volumenTablon()
    {
        let inicio:number=0;
        let suma =this.tablon.reduce(function(total,currentValue){
            return total + Number(currentValue.volumen)
        },inicio)
        return suma;
    }

    get mensajeTurno()
    {
        const errors: string[] = [];
        if(!this.$v.turnoId.$dirty) return errors;
        !this.$v.turnoId.minValue && errors.push("Valor minimo es 1");
        !this.$v.turnoId.required && errors.push("El Campo es requerido");
        return errors;
    }

    get mensajeProduccion()
    {
        const errors: string[] = [];
        if(!this.$v.volumenProduccion.$dirty) return errors;
        !this.$v.volumenProduccion.minValue && errors.push("Valor minimo es 1");
        !this.$v.volumenProduccion.required && errors.push("El Campo es requerido");
        return errors;
    }


    async mounted() 
    {
        //this.listadoTurno = (await ObtenerTurnoPlanta(this.plantas[0].plantaId)).isResult
    }
}
